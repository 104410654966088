// import Swiper JS
import Swiper from 'swiper/bundle';
// import Swiper styles
// import 'swiper/css';

const testimonials = () => {
  const elements = document.querySelectorAll('.block--testimonial');
  if (!elements) return;

  elements.forEach( (element) => {
    const swiperElement = element.querySelector('.block__content');
    var swiper = new Swiper(swiperElement, {
      // autoHeight: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
        type: "progressbar",
      },
      // scrollbar: {
      //   el: ".swiper-scrollbar",
      //   hide: false,
      // }
    });
  })

}

export default testimonials;