import "../css/styles.scss";
import renderLabels from "./utils/componentLabels";
import menuToggle from "./layout/menuToggle";
import mobileNavigation from "./layout/navigation";
import accordions from "./components/blocks/accordions";
import webinars from "./components/banners/webinar";
import faqSections from "./components/blocks/faqSections";
import testimonials from "./components/blocks/testimonial";

if (import.meta.env.MODE === 'development') {
  renderLabels();  
}

const init = () => {
  menuToggle();
  mobileNavigation();
  accordions();
  // webinars();
  faqSections();
  testimonials();
}
console.log(import.meta.env.MODE)


window.addEventListener("load", () => {
  document.querySelector('body').classList.remove('preload');
  init();
}, false);

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR")
  });
}