import toggleState from "../utils/toggleState";
let mql = window.matchMedia('(max-width: 991px)');

const toggleMenu = (e) => {
  e.preventDefault();
  console.log('nav button test');
  const openPanels = document.querySelectorAll('.panel--offscreen[data-state=open]');
  toggleState('.nav--button', 'closed', 'open');
  toggleState('nav#nav-main', 'closed', 'open');
  toggleState('body', 'noscroll', 'scroll');

  if(!openPanels) return;
  openPanels.forEach(el => el.dataset.state = "closed");
}

const menuToggle = () => {
  function onLoadFunction(e) {
    onResizeFunction();
    window.addEventListener("resize", onResizeFunction);
  }

  onLoadFunction();

  function onResizeFunction(e) {

    const navButton = document.querySelector('.nav--button');
    const navMain = document.querySelector('nav#nav-main');
    const body = document.querySelector('body');

    if (mql.matches) {
      const menuButton = document.querySelector('.nav--button');
      menuButton.addEventListener('click', toggleMenu);
    } else {
      console.log('desktop');
      navButton.setAttribute('data-state', 'closed');
      navMain.setAttribute('data-state', 'closed');
      body.setAttribute('data-state', 'scroll');
    }
  }
}

export default menuToggle;