import toggleState from "../utils/toggleState";

let mql = window.matchMedia('(max-width: 991px)');

const mobileNavigation = () => {
  function onLoadFunction(e) {
    onResizeFunction();
    window.addEventListener("resize", onResizeFunction);
  }

  onLoadFunction();
  
  function onResizeFunction(e) {
    if (mql.matches) {
      const navigationElements = document.querySelectorAll('#nav-main > ul:first-of-type > li.has__sub__menu');
      navigationElements.forEach((item) => {
        item.addEventListener('click', (e) => {
          e.stopPropagation();
          const target = e.target.parentNode;
          if (target.getAttribute('data-state') == 'closed') {
            target.setAttribute('data-state', 'open')
          } else {
            target.setAttribute('data-state', 'closed');
          }
        })
      })
    }
  }

  const navElement = document.querySelector('header nav#nav-main');
  const mainNav = document.querySelector('header ul#main');
  let targetPanel;
  let openPanel = false;

  const navLinks = mainNav.querySelectorAll(':scope > li.level--1.has__sub__menu');
  navLinks.forEach((link) => {
    link.addEventListener('click', (e) => {
      const targetPanelID = e.target.id;
      targetPanel = document.querySelector(`.panel--offscreen#${targetPanelID}`);
      targetPanel.dataset.state = "open";
      setTimeout(function() {
        // Code to be executed after the delay of 0.5 seconds
        openPanel = true;
      }, 500);
    })
  })

  document.addEventListener('click', function(event) {
    if (!openPanel) return;
    const clickedElement = event.target;
    if (!targetPanel.contains(clickedElement)) {
      targetPanel.setAttribute('data-state', 'closed');
      openPanel = false;
    }
  });

  // const childMenus = mainNav.querySelectorAll('ul.sub__menu--level-1');

  // childMenus.forEach( (menu) => {
  //   const menuID = menu.getAttribute('id');
  //   const clonedMenu = menu.cloneNode(true);
  //   const newDiv = document.createElement('div');
  //   newDiv.classList.add("panel--offscreen");
  //   newDiv.id = menuID;
  //   newDiv.appendChild(clonedMenu);
  //   newDiv.dataset.state = 'closed';
  //   document.body.appendChild(newDiv);
  // })

  const panelBackButtons = document.querySelectorAll('.panel--offscreen button');
  panelBackButtons.forEach((button) => {
    button.addEventListener('click', (e) => {
      e.preventDefault();
      const targetPanelID = e.target.parentNode.id;
      const targetPanel = document.querySelector(`.panel--offscreen#${targetPanelID}`);
      targetPanel.dataset.state = 'closed';
      openPanel = false;

      navElement.setAttribute('style', 'overflow-y: hidden');
    });
  })
}

export default mobileNavigation;