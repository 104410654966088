import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
let mm = gsap.matchMedia();

const webinars = () => { 
  // create

// add a media query. When it matches, the associated function will run
mm.add("(min-width: 767px)", () => {
  let banner = document.querySelector(".block--banner-simple--webinar");
  if (!banner) return;

  // Get the elements
  const element1 = document.querySelector('main');
  const element = banner.querySelector(".outerwrapper");
  // const element2 = banner.getElementById('element2');

  // Get the bottom positions
  const rect1 = element1.getBoundingClientRect();
  const rect2 = element.getBoundingClientRect();

  // Calculate the distance in pixels
  const distance = rect2.top - rect1.bottom + 92 ;
  
  ScrollTrigger.create({
    trigger: element,
    start: "top top",
    end: `bottom+=${distance * -1}px bottom`,
    pin: true,
    markers: false
  }); 

  return () => { // optional
    // custom cleanup code here (runs when it STOPS matching)
  };
});

// later, if we need to revert all the animations/ScrollTriggers...
// mm.revert();

}

export default webinars;