import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
let mm = gsap.matchMedia();

const faqSections = () => { 
  const section = document.querySelector('.block--faq-sections');
  if (!section) return;

  const element = section.querySelector('.outerwrapper');
  const links = element.querySelectorAll('ul li');
  const sections = gsap.utils.toArray(".block--faq-sections__block", section);
  console.log({sections})

  function setActive(section) {
    sections.forEach(el => el.classList.remove("active"));
    links.forEach(el => el.classList.remove("active"));
    const target = section.getAttribute("id");
    const activeLink = document.querySelector(`#link-${target}`);
    activeLink.classList.add("active");
    section.classList.add("active");
  }

  ScrollTrigger.create({
    trigger: section,
    start: "top top-=92px",
    end: "bottom bottom",
    pin: element,
    markers: false
  }); 

  sections.forEach((item, i) => {
    console.log({sections})
    ScrollTrigger.create({
      trigger: item,
      start: "top 50%",
      end: "bottom 50%",
      toggleActions: "play pause resume reset",
      onEnter: self => self.isActive && setActive(item),
      onEnterBack:self => self.isActive && setActive(item),
      markers: false
    });
  });
}

export default faqSections;